





































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'BaseModal'
})
export default class BaseModal extends Vue {
  @Prop({ default: '688px' }) cardWidth!: string;

  @Prop({ default: '698px' }) cardHeight!: string;

  @Prop({ default: null }) title!: string;

  @Prop({ default: '' }) contentClass!: string;

  @Prop({ default: true }) showCloseButton!: boolean;
}
